body {
  margin: 0px;
}

.MuiAccordion-root:before {
  opacity: 0 !important;
}

.menu-edit-table {
  border-spacing: 15px;
}

.menu-edit-table td {
  text-align: center;
}

.menu-edit-table-container {
  width: 100%;
  overflow-x: auto;
}

@media screen and (max-width: 700px) {
  .standard-menu {
    display: none !important;
  }

  .mobile-menu {
    display: block !important;
  }
}

.icon-button {
  outline: none;
  box-shadow: none;
  background-color: transparent;
  border: none;
  transition: 0.3s;
}

.icon-button:hover {
  transform: scale(1.1);
  cursor: pointer;
}

.zoom-on-hover {
  transform: scale(1);
  transition: all 0.3s !important;
}

.zoom-on-hover:hover {
  transform: scale(1.01);
  cursor: pointer;
}
